import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../components/layout';
import Container from '../components/Container';
import urls from '../data/urls';
import {
  SectionStyled,
  HeaderH3,
  ContactLink,
  ContactGatsbyLink,
} from '../components/commons-styles';
import Offers from '../container/Homepage/SectionOffer/Offers';
import Jumbotron from '../components/Jumbotron';
import fullpageImg from '../images/car.jpg';
import { EMAILHREF, EMAIL, FULL_PAGE_URL } from '../../settings';


function OfferPage() {
  return (
    <Layout>
      <Helmet
        title="Lista świadczonych usług plandekowych przez firmę Dysplan"
        link={[{ rel: 'canonical', href: `${FULL_PAGE_URL}/oferta/` }]}
        meta={[
          {
            name: 'description',
            content: 'Lista świadczonych usług plandekowych przez firmę Dysplan',
          },
          {
            name: 'keywords',
            content:
              'oferta usług plandekowych, usługi plandekowe, oferty plandekowe',
          },
          { name: 'robots', content: 'index, follow' },
        ]}
      />
      <Jumbotron
        header="Oferta usług firmy Dysplan"
        subHeader="Przedstawiamy całą ofertę firmy Dysplan. Profesjonalnie wykonane plandeki, banery w Małopolsce.
          Głównie działamy w okolicach Krakowa, ale też nie boimy się wyzwań w Polsce i za granicą!"
        minHeight="auto"
        minHeightMobile="auto"
        heightJumb="400px"
        srcImage={fullpageImg}
      />
      <Container>
        <SectionStyled>
          <Offers />
        </SectionStyled>
      </Container>
      <Container breakpoint="verysmall">
        <SectionStyled>
          <HeaderH3>Jak składać zamówienia?</HeaderH3>
          <p>
            Ze względu na to, że bardzo zależy nam na Państwa zadowoleniu,
            chcemy świadczyć usługi na najwyższym poziomie. Z uwagi na ilość
            zadań do wykonania, najszybszym sposobem na złożenie zamówienia jest
            kontakt mailowy z nami. Aby proces zamówienia przebiegał sprawnie,
            poniżej podajemy wskazówki jakie informacje należy zawrzeć w mailu:
          </p>
          <ul>
            <li>1. Produkt z oferty, który chcą Państwo zamówić</li>
            <li>2. Wymiary zamówienia</li>
            <li>3. Kolor zamówienia</li>
            <li>4. Tekst / nadruk / logo</li>
            <li>
              5. Zdjęcie obiektu (dotyczy ogródków, balkonów do zabudowy,
              samochodów do oklejenia, zleceń nietypowych)
            </li>
            <li>
              6. Wysłać maila na adres
              {' '}
              <ContactLink href={EMAILHREF}>{EMAIL}</ContactLink>
            </li>
            <li>
              7. W przypadku nieotrzymania odpowiedzi w przeciągu 24 godzin,
              bardzo prosimy o
              {' '}
              <ContactGatsbyLink to={`${urls.get('home')}#kontakt`}>
                kontakt telefoniczny
              </ContactGatsbyLink>
            </li>
          </ul>
        </SectionStyled>
      </Container>
    </Layout>
  );
}

export default OfferPage;
